import { ComponentOptions } from 'vue'

export default <ComponentOptions>{
  data: () => {
    return {
      showNavigation: false,
    }
  },
  methods: {
    toggleNavigation() {
      this.showNavigation = !this.showNavigation
    },
  },
}
